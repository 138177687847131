body {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.form-control {
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6, .navbar-brand, .heading-font {
  font-family: 'Raleway', sans-serif;
}

.container.email {
  max-width: 600px;
}

.tag-card:hover {
  opacity: 0.9;
}

.shadow-sm {
  box-shadow:0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow:0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow:0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.table td, .table th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}

.feed-item-enter {
  opacity: 0.01;
}

.feed-item-enter.feed-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.feed-item-leave {
  opacity: 1;
}

.feed-item-leave.feed-item-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.extra-info-enter {
  opacity: 0.01;
}

.extra-info-enter.extra-info-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.extra-info-leave {
  opacity: 1;
}

.extra-info-leave.extra-info-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}